exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-us-js": () => import("./../../../src/pages/about/about-us.js" /* webpackChunkName: "component---src-pages-about-about-us-js" */),
  "component---src-pages-about-areas-covered-js": () => import("./../../../src/pages/about/areas-covered.js" /* webpackChunkName: "component---src-pages-about-areas-covered-js" */),
  "component---src-pages-about-our-offices-js": () => import("./../../../src/pages/about/our-offices.js" /* webpackChunkName: "component---src-pages-about-our-offices-js" */),
  "component---src-pages-about-sample-reports-js": () => import("./../../../src/pages/about/sample-reports.js" /* webpackChunkName: "component---src-pages-about-sample-reports-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-services-components-side-bar-js": () => import("./../../../src/pages/services/components/SideBar.js" /* webpackChunkName: "component---src-pages-services-components-side-bar-js" */),
  "component---src-pages-services-dilapidations-js": () => import("./../../../src/pages/services/dilapidations.js" /* webpackChunkName: "component---src-pages-services-dilapidations-js" */),
  "component---src-pages-services-party-wall-js": () => import("./../../../src/pages/services/party-wall.js" /* webpackChunkName: "component---src-pages-services-party-wall-js" */),
  "component---src-pages-services-reports-building-survey-js": () => import("./../../../src/pages/services/reports/building-survey.js" /* webpackChunkName: "component---src-pages-services-reports-building-survey-js" */),
  "component---src-pages-services-reports-defect-analysis-js": () => import("./../../../src/pages/services/reports/defect-analysis.js" /* webpackChunkName: "component---src-pages-services-reports-defect-analysis-js" */),
  "component---src-pages-services-reports-home-buyers-js": () => import("./../../../src/pages/services/reports/home-buyers.js" /* webpackChunkName: "component---src-pages-services-reports-home-buyers-js" */),
  "component---src-pages-services-schedules-of-condition-js": () => import("./../../../src/pages/services/schedules-of-condition.js" /* webpackChunkName: "component---src-pages-services-schedules-of-condition-js" */),
  "component---src-pages-services-snagging-list-js": () => import("./../../../src/pages/services/snagging-list.js" /* webpackChunkName: "component---src-pages-services-snagging-list-js" */),
  "component---src-pages-services-valuations-capital-gains-tax-js": () => import("./../../../src/pages/services/valuations/capital-gains-tax.js" /* webpackChunkName: "component---src-pages-services-valuations-capital-gains-tax-js" */),
  "component---src-pages-services-valuations-charities-act-js": () => import("./../../../src/pages/services/valuations/charities-act.js" /* webpackChunkName: "component---src-pages-services-valuations-charities-act-js" */),
  "component---src-pages-services-valuations-matrimonial-js": () => import("./../../../src/pages/services/valuations/matrimonial.js" /* webpackChunkName: "component---src-pages-services-valuations-matrimonial-js" */),
  "component---src-pages-services-valuations-probate-js": () => import("./../../../src/pages/services/valuations/probate.js" /* webpackChunkName: "component---src-pages-services-valuations-probate-js" */),
  "component---src-pages-services-valuations-red-book-js": () => import("./../../../src/pages/services/valuations/red-book.js" /* webpackChunkName: "component---src-pages-services-valuations-red-book-js" */),
  "component---src-pages-services-valuations-shared-ownership-js": () => import("./../../../src/pages/services/valuations/shared-ownership.js" /* webpackChunkName: "component---src-pages-services-valuations-shared-ownership-js" */),
  "component---src-pages-surveys-building-survey-sample-js": () => import("./../../../src/pages/surveys/building-survey-sample.js" /* webpackChunkName: "component---src-pages-surveys-building-survey-sample-js" */),
  "component---src-pages-surveys-choosing-a-survey-js": () => import("./../../../src/pages/surveys/choosing-a-survey.js" /* webpackChunkName: "component---src-pages-surveys-choosing-a-survey-js" */),
  "component---src-pages-surveys-home-buyers-sample-js": () => import("./../../../src/pages/surveys/home-buyers-sample.js" /* webpackChunkName: "component---src-pages-surveys-home-buyers-sample-js" */),
  "component---src-pages-surveys-valuation-sample-js": () => import("./../../../src/pages/surveys/valuation-sample.js" /* webpackChunkName: "component---src-pages-surveys-valuation-sample-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

